@import "../scss/fonts";
@import "../scss/variables";

* {
	font-family: 'Raleway', sans-serif;
	box-sizing: border-box;
	position: relative;
	text-align: left;
}

html, body {
	width: 100%;
	height: 100%;
	min-height: 100%;
	background: #fff;
	color: #000;
	font-display: swap;
}
	
body {
	text-rendering: optimizeLegibility;
}
	
body, ul, ol, dl, p, h1, h2, h3, h4, h5, dl, dt, dd {
	margin: 0;
}

img {
	display: inline-block;
}

h1, h2 {
	@include primary_headline;
	font-weight: 300;
}

h3 {
	@include secondary_headline;
	font-weight: 500;
}

a {
	text-decoration: none;
	color: #000;
	font-weight: 500;
	@include standard-transition(color, 0.25s, 0s);

	&:hover {
		color: $medium-grey;
	}
}

ul {
	padding: 0;
	list-style-type: disc;
}

.hidden {
	display: none;
}

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
} 

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}

.no_margin_top {
	margin-top: 0 !important;
}

.pageWrapper {
	padding-top: 60px;
}

.fixed_width {
	position: relative;
	width: 100%;
	max-width: 1480px;
	padding-left: 40px;
	padding-right: 40px;
	margin: $section_spacer auto;
}

.full_width {
	width: 100%;
	margin: 0 auto;
}

hr {
	display: block;
	width: 100%;
	height: 1px;
	background: $light-grey;
	border: none;
	margin: 0;
}

@media screen and (max-width:680px) {
	.fixed_width {
		padding: 0 20px;
		margin: 20px 0;
	}
}