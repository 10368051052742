@import "../scss/variables";
.moodPicBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transform: scale(1.01);
    @include standard-transition(transform, 0.5s, 0s);

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: rgb(87, 87, 87), $alpha: 1);
        @include standard-transition(background, 0.5s, 0s);
    }
}

.moodPicBg.loaded:before {
    background: rgba($color: #000, $alpha: 0.6);
}

.ginOverviewTile a:hover {
    .moodPicBg {
        transform: scale(1.05);
    }
    
    .moodPicBg.loaded:before {
        background: rgba($color: #000, $alpha: .45);
    }
}