@import "../scss/variables";

footer {
    position: relative;
    height: $footer_height;

    section {
        text-align: center;
        background: $light-grey;
        width: auto;
        height: 100%;
        position: relative;

        &.footer-home {
            position: absolute;
            z-index: 1000;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            background: rgba($light-grey, 0);

            a {
                color: $light-grey;
                height: 100%;
                padding: auto 40px;
                
                &:hover {
                    color: #fff;
                }
            }

        
        }

        a {
            padding: calc(($footer_height - 12px)/2) 40px calc(($footer_height - 12px)/2);
            display: inline-block;
            @include label-text;
            color: $medium-grey;
            @include standard-transition(all, 0.25s, 0s);
            
            &:hover {
                color: $dark-grey;
                background: rgba($light-grey, 0.1);
            }
        }
    }
}