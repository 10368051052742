@import "../scss/variables";

.headlineTextListImage {

    &:nth-child(odd) {
        background-color: $light-grey;
    }

    .contentWrap {
        display: flex;
        column-gap: 20px;
        padding-top: 100px;
        padding-bottom: 100px;

        &.fixed_width {
            margin: 0 auto;
        }
    }

    .imgWrap {
        width: calc(100% * 1/4);
        
        img {
            display: block;
            @include drop-shadow-light;
            width: 100%;
        }
    }
    

    .textContent {
        margin-left: 40px;
        width: 50%;

        h2 {
            font-size: 80px;
            line-height: 90px;
        }
        
        p {
            @include simple_text
        }

        ul {
            width: 75%;
            margin-top: 40px;

            li {
                display: inline-flex;
                margin-top: 20px;
                @include larg_ul_text;
                   
                &:before {
                    content: "▶";
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 12px;
                    line-height: 12px;
                    margin-top: 10px;
                }

                &:first-child {
                    margin-top: 0px;
                }
            }
        }
    }

    &:nth-child(even) {
        background-color: none;

        .contentWrap {
            flex-direction: row-reverse;
        }

        .textContent {
            margin-left: 0px;
            margin-right: 40px;
            justify-self: end;
        }
    }
}


@media screen and (max-width:1100px) {

    .headlineTextListImage {

        .imgWrap {
            width: calc(100% * 1/3);
        }
        .textContent {
            width: calc(100% * 2/3);
        }
    }
}

@media screen and (max-width:900px) {

    .headlineTextListImage .textContent ul {
        margin-top: 30px;

        li {
            margin-top: 15px;
            font-size: 16px;
            line-height: 24px;
        }

        li:before {
            margin-top: 7px;
        }
    }
}

@media screen and (max-width:800px) {
  
    .headlineTextListImage .textContent ul {
        width: 100%;
    }
}

@media screen and (max-width:680px) {

    .headlineTextListImage {

        .contentWrap {
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .imgWrap {
            width: 100%;
            position: relative;
            text-align: center;

            &::before {
                display: block;
                content: "";
                position: absolute;
                width: 110%;
                height: 75%;
                bottom: -10%;
                left: -5%;
                background: linear-gradient(180deg, rgba($light-grey,0) 0%, rgba($light-grey,1) 80%); 
                z-index: 500;
            }

            img {
                display: inline-block;
                width: auto;
                height: 250px;
                position: relative;
                z-index: 250;
            }
        }

        .textContent {
            position: relative;
            z-index: 1000;
            width: 100%;
            margin-top: -100px;
            margin-left: 0;

            h2 {
                margin-bottom: 10px;
                text-align: center;
            }

            ul {
                margin-top: 10px;
            }
        }

        &:nth-child(even) {
    
            .contentWrap {
                flex-direction: column;
            }

            .imgWrap {
                &::before {
                    background: linear-gradient(180deg, rgba(#fff,0) 0%, rgba(#fff,1) 80%); 
                }
            }

            .textContent {
                margin-right: 0px;
                justify-self: start;
            }
    
        }
    }
}

@media screen and (max-width:500px) {
    .headlineTextListImage .textContent {
        margin-top: -80px;

        h2 {
            font-size: 60px;
            line-height: 70px;
        }
    }
}

@media screen and (max-width:390px) {
    .headlineTextListImage .textContent {
        margin-top: -60px;
        
        h2 {
            font-size: 40px;
            line-height: 46px;
        }
    }
}