@import "../scss/variables";

.linkButton {
    display: inline-block;
    width: auto;
    height: auto;
    background:  #303030;
    opacity: 0.85;
    @include standard-transition(all, 0.25s, 0s);

    a {
        display: table;
        width: auto;
        margin: 0;
        font-size: 14px;
        line-height: 14px;
        font-family: "Raleway_Bold";
        text-align: center;
        height: 32px;
        color: $light-grey;
        padding: 9px 40px 0px;
    }

    &:hover {
        background: #000;
        opacity: 1;

        a {
            color: #fff;
        }
    }
}