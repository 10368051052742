@import "../scss/variables";

h1 {
  @include primary_headline;
  display: inline-block;
}

.detailed_description, 
.linkButton {
  margin-top: 30px;
}

.detailed_description {
  dl {
    $margin-top: 10px;

    &>* {
      float: left;
      margin-top: $margin-top;
    }

    dt {
      @include label_text;
      opacity: 0.75;
      display: inline-block;
      width: 120px;
      margin-top: calc(#{$margin-top} + 7px);
      clear: left;

      &:after {
        content: ": ";
        display: inline-block;
      }
    }

    dd {
      @include simple_text;
      display: inline-block;
      width: calc(100% - 120px);
      max-width: 450px;
    }
  }
}

@media screen and (max-width:800px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .tags_list,
  .detailed_description, 
  .linkButton {
    margin-top: 20px;
  }
}

@media screen and (max-width:460px) {
  .detailed_description {
    dl {
      dt {
        display: block;
        width: 100%;
        clear: left;
        margin-top: 20px;

        &:after {
          content: ": ";
          display: inline-block;
        }
      }

      dd {
        display: block;
        width: 100%;
        max-width: 100%;
        margin-top: 6px;
      }
    }
  }
}