@import "../scss/variables";

.back_link {
	background: $dark-grey;
	padding: 30px;
	text-align: center;

	a {
		display: inline-block;
		@include label_text($light-grey);
		@include standard-transition(all);
		text-decoration: none;

		&:before {
			display: inline-block;
			content: "⟵";
			font-size: 26px;
			line-height: 12px;
			margin-right: 10px;
			@include standard-transition(all);
			transform: translateX(0px);
		}

		&:hover {
			text-decoration: underline;
		}

		&:hover:before {
			transform: translateX(-20px);
		}

	}
}