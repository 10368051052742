@import "../scss/variables";

.ginOverviewTile {    
    width: auto;
    position: relative;
    overflow: hidden;

    &>a {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;

        &:hover {

            img {
                transform: scale(1.05);
                @include standard-transition(transform, 0.5s, 0s);
            }

            h2 {
                
            }

            .viewMore {
                height: 32px;
                color: $dark-grey;
                padding-top: 9px;
                opacity: 0.85;
            }

            .tagsList {
                opacity: 0;
                transform: translateY(-5px);
            }
        }
    }
}

.imageWrapper {
    display: block;
    height: 150px;
    margin: 10px 0 20px;
}


.tileContent {
    position: relative;
    z-index: 200;
    padding: 20px;
    width: 100%;
    
    img {
        aspect-ratio: 187/400;
        display: block;
        width: auto;
        height: 150px;
        margin: 0 auto;
        transform: scale(1);
        @include drop-shadow-dark;
    }
    
    h2 {
        @include tile_headline;
        margin-bottom: 10px;
    }
}

.tagsViewMoreWrapper {
    position: relative;

    hr {
        opacity: 0.75;
    }

    .viewMore {
        @extend .button;
        font-size: 14px;
        line-height: 14px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        padding-top: 0px;
        height: 0px;
        color: $light-grey;
        transition: padding .25s, 
                    opacity .25s,
                    opacity .25s,
                    height .25s, 
                    color .25s 0.25s;
    }

    .tagsList {
        position: relative;
        opacity: 0.85;
        margin-top: 10px;
        transform: none;
        @include standard-transition(all, 0.25s, 0s);

        .tags_list {
            margin-top: 0;
        }
    }
}

#recommendation {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 0;
    width: 40%;
    text-align: center;
    background: rgba($color: #fff, $alpha: 0.8);
    transform: rotate(45deg);
    right: -11%;
    top: 2%;
    visibility: hidden;

    span {
        @include label_text;
    }

    &.show {
        visibility: visible;
    }
}

@media screen and (max-width:680px) {
    .tileContent {
        padding: 20px;

        img {
            aspect-ratio: 187/400;
            height: auto;
            max-height: 150px;
            width: 35%;
            margin: 0px auto 10px;
        }

        h2 {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 10px;
        }

        .tagsList, .tags_list {
            margin-top: 10px !important;
        }
    }
}

@media screen and (max-width:450px) {
    .tileContent {
        padding: 20px 10px 10px;
    }
}