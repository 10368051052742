@import "../../scss/variables";

.impressum {
   
    p {
        margin-top: 40px;
    }

    p:first-child {
        margin-top: 0px;
    }

    h2 {
        @include secondary_headline;
        font-family: 'AdventPro_Light';
        margin-bottom: 30px;
    }
}

