@import "../scss/variables";

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 1000;
	padding: 0 !important;
	z-index: 500;

	.navWrap {
		height: $header_height;
		background: rgba(#fff, 0.95);
	}

	.navigation {
		position: relative;
		width: 100%;
		max-width: 1480px;
		padding-left: 40px;
		padding-right: 40px;
		margin: 0 auto;
		height: 100%;
	}

	nav {
		display: inline-block;
		position: relative;
		z-index: 600;
	
		* {
			@include nav_item_text;
		}
	
		a {
			display: inline-block;
			float: left;
			padding: calc(($header_height - 20px)/2)-1 40px calc(($header_height - 20px)/2)+1;
			background: rgba(#fff, 0);
			@include standard-transition(all, 0.25s, 0s);
			
			&:before {
				display: inline-block;
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				width: 1px;
				height: 20px;
				background: $medium-grey;
				transform: translateY(-50%);
			}
	
			&:first-child:before {
				// display: none;
			}
		}
	
		a:hover {
			background: rgba($color: $light-grey, $alpha: 1);
			color: $dark-grey
		}
	
		.active {
			color: $dark-grey;
			text-decoration: underline;
		}

		a.imprint {
			display: none;
			visibility: hidden;
			opacity: 0;
		}
	}

	.mobileNavWrap {
		display: inline-block;
		opacity: 0;
		position: absolute;
		z-index: 700;
		top: 0;
		right: 0;
		width: 60px;
		height: 60px;
		background-color: rgba(#fff, 0);
		padding: 17px 12px 19px;
		cursor: pointer;

		.mobileNav {
			position: relative;
			width: 100%;
			height: 100%;
		}


		span {
			position: absolute;
			display: block;
			width: 100%;
			height: 1px;
			top: 50%;
			opacity: .5;
			background: #000;
			@include standard-transition(all, 0.25s, 0s);

			&.st {
				top: 0%;
			}

			&.rd {
				top: 100%;
			}
		};

		&:hover {

			span {
				opacity: 1;
			}
		}
	}

	&.dark {
		background: transparent;

		nav .active {
			color: #fff;
		}

		nav a:hover {
			background: rgba(#fff, .1);
			color: $medium-grey;
		}

		.logo{

			#logo {
				fill: #fff;
			}

			&:hover {
				background: rgba(#fff, .1);
			}
		} 

		.mobileNavWrap {
			span {
				background: #fff;
			}
		}

		.navWrap {
			background: rgba(#fff, 0);
		}
	}

	&.activeNav {
		.mobileNavWrap {
			span.st,
			span.rd {
				top: 50%;
				transform: rotate(225deg);
			}
			span.nd {
				opacity: 0;
			}
			span.rd {
				transform: rotate(135deg);
			}
		}

		nav .active {
			color: initial;
		}
	}

	.logo {
		width: auto;
		float: left;
		height: 100%;
		z-index: 700;
		background: rgba( $light-grey, 0);
		@include standard-transition(all, 0.25s, 0s);

		a {
			display: inline-block;
			width: auto;
			height: 100%;
			padding: 10px 17px;
		}

		svg {
			width: auto;
    		height: 100%;
		}

		#logo {
			fill: $dark-grey;
		}

		&:hover {
			background: rgba($color: $light-grey, $alpha: 1);
		}
		
	}
}

@media screen and (max-width:800px) {
	header {
		width: 100%;
		height: auto;
		background: transparent;

		.navigation {
			padding: 0;
		}

		nav {
			position: absolute;
			top: 0;
			left: 0;
			padding-top: 60px;
			height: 100vh;
			background: rgba(255, 255, 255, 1);
			visibility: hidden;
    		opacity: 0;
			display: none;
			@include standard-transition(all, 0.25s, 0s);

			a {
				text-align: center;
				width: 100%;
				padding: 40px 0;
				color: $medium-grey;
				font-size: 20px;
				list-style: 20px;
				border-bottom: 1px solid rgba($medium-grey, 0.5);

				&:first-child {
					border-top: 1px solid rgba($medium-grey, 0.5);
				}

				&:before {
					display: none;
				}

				&:hover {
					color: $dark-grey !important;
					background: rgba($color: #fff, $alpha: .75) !important;
				}

				&.active {
					// color: #000 !important;
					// background: rgba($color: #fff, $alpha: .75);
				}
			}

			a.imprint {
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}

		&.activeNav {

			nav {
				visibility: visible;
    			opacity: 1;
				display: block;
				width: 100%;
				padding-top: 20vh;
			}
			
			.logo {
				#logo {
					fill: $dark-grey;
				}
			}
			
			.mobileNavWrap {
				span {
					background: $dark-grey;
				}

				&:hover {
					span {
						background: #000;
					}
					
				}
			}
		}

		.mobileNavWrap {
			display: block;
			opacity: 1;
		}
	}
  }