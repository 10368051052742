@import "../scss/variables";

.HeadlineTextGradientBg {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-row: 1;
    padding: 80px 0;
  
    background: $grey-transparent-gradient;
}

.image {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 10px;

    img {
        width: 150px;
        height: auto;
        display: block;
        margin: 0 auto;
        
        @include drop-shadow-light;
    }
}

.description {
    grid-column-start: 2;
    grid-column-end: 3;
    padding-right: 40px;

    h1, h2 {
        text-align: left;
    }

    & * {
        position: relative;
    }

    &>*:first-child {
        margin-bottom: 20px;
    }

    p {
        @include simple_text;
        max-width: 500px;
    }
}

@media screen and (max-width:1000px) {
    .HeadlineTextGradientBg {
        padding: 60px 0;
    }
}

.image {
    img {
        max-height: 90%;
        max-width: 70%;
        object-fit: contain;
    }
}

@media screen and (max-width:650px) {
    .HeadlineTextGradientBg {
        display: block;
        padding: 40px;

        .image {
            padding: 0;
            height: 200px;

            img {
                width: auto;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .description {
            padding: 0px;
            margin-top: 20px;

            h1 {
                font-size: 36px;
                line-height: 49px;
                margin-bottom: 20px;
                text-align: left;
            }

            .tags_list {
                margin-top: 0;
            }
        }
        
    }
}