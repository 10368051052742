@import "../scss/variables";

.tags_list {
	display: flex;
	row-gap: 10px;
	flex-wrap:wrap;
	padding: 0 6px;
	margin: 0 -12px;

	span {
		$tags-size: 16px;
		display: inline-block;
		width: auto;
		height: $tags-size;
		background: #000;
		opacity: 0.95;
		@include tag_text($light-grey);
		font-size: 10px;
		line-height: 10px;
		border-radius: calc($tags-size / 2);
		padding: calc(($tags-size - 10px) / 2) 12px 0px;
		text-align: center;
		margin: 0 6px;
	}

	&.inverted span {
		color: $dark-grey;
		background: $light-grey;
	}
}

@media screen and (max-width:680px) {
    .tags_list {
		row-gap: 4px;
		padding: 0 10px;
	
		span {
			margin: 0 2px;
		}
	}
}