@import "../scss/variables";

.headline_text_emphesized {
	position: relative;
	text-align: center;
	width: auto;
	max-width: 800px;
	margin: 0 auto;

	.headline {
		display: flex;
		column-gap: 25px;
		align-items: center;

		h2 {
			@include primary_headline;
			display: inline-block;
			width: auto;
			margin-bottom: 0;
		}

		span {
			display: inline-block;
			width: auto;
			height: 1px;
			background: $medium-grey;
			flex-grow: 1;
		}
	}

	p {
		@include emphasized_text($medium-grey);
		text-align: left;
		max-width: 600px;
		margin: 15px auto 0;

		ul {
			display: block;
			width: 100%;
			padding-left: 10px;

			li {
				font-size: 20px;
				line-height: 30px;
			}
		}

		a {
			font-style: italic;
		}
	}
}

@media screen and (max-width:1000px) {   
    .headline_text_emphesized {
		width: auto;

		.headline h2 {
			font-size: 42px;
			line-height: 56px;
		}
	}
}

@media screen and (max-width:680px) {   
    .headline_text_emphesized {
		.headline h2 {
			font-size: 30px;
			line-height: 40px;
		}

		p {
			padding: 0 40px;
			font-size: 16px;
			line-height: 32px;

			ul {
				li {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
}