
@import "../scss/variables";

.progressive-image {
    opacity: 0%;
}

.progressive-image.loaded {
    opacity: 100%;
    @include standard-transition(opacity, 0.3s, 0s);
}