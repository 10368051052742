@import "../scss/variables";

.alternating_banner_section {
	display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;  

    &.fixed_width {
        margin-top: 0;
        margin-bottom: 0;
    }

    .banner-content-wrapper {
        position: relative;
        width: 100%;
        max-width: 1480px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        justify-content: flex-start;
    }

    .banner-content {
        width: 100%;
        max-width: 500px;

        h2 {
            font-size: 58px;
        }

        p {
            @include simple_text;
            margin-top: 20px;
        }
    }
    
    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
    }
    
    .bg-img:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .banner-text {
        @include emphasized_text;
    }

    .bg-img.noOverlay:before {
        display: none;
        visibility: collapse;
        opacity: 0;
    }

    &.dark {
        .banner-content * {
            color: $light-grey;
        }
        &.left {
            
            .bg-img:before {
                background: linear-gradient(to left, rgba(#000,0.5), rgba(#000,1) 75%);
            }
        }
        &.right {
            .bg-img:before {
                background: linear-gradient(to right, rgba(#000,0.5), rgba(#000,1) 75%);
            }
        }
    }
    &.light {
        .banner-content * {
            color: $dark-grey;
        }
        &.left {
            
            .bg-img:before {
                background: linear-gradient(to left, rgba(#fff,0.5), rgba(#fff,1) 75%);
            }
        }
        &.right {
            .bg-img:before {
                background: linear-gradient(to right, rgba(#fff,0.5), rgba(#fff,1) 75%);
            }
        }
    }
    &.left {
        .banner-content-wrapper {
            justify-content: flex-start;
        }
        
        .banner-content {
            margin-left: 120px;
            margin-right: 0px;
        }
    }
    &.right {
        .banner-content-wrapper {
            justify-content: flex-end;
            margin-top: -30px;
        }
        .banner-content {
            margin-left: 0px;
            margin-right: 120px;
        }
    }
}

@media screen and (max-width:1200px) {
    .alternating_banner_section {
        &.left {
            .banner-content {
                margin-left: 40px;
            }
        }
        &.right {
            .banner-content {
                margin-right: 40px;
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .alternating_banner_section {
        .banner-content {
            max-width: 500px;
        }
    }
}
    

@media screen and (max-width:760px) {
    .alternating_banner_section {
        padding-top: 60px;
        padding-bottom: 60px;

        .banner-content-wrapper {
            justify-content: flex-start !important;
        }

        .banner-content {
            max-width: 100%;
            margin: 0 !important;

            h2 {
                font-size: 40px;
                line-height: 50px;
            }
        }

        &.light {
            &.right, &.left {
                .bg-img:before {
                    background: linear-gradient(to left, rgba(#fff,0.75), rgba(#fff,1) 75%);
                }
            }
        }
        &.dark {
            &.right, &.left {
                .bg-img:before {
                    background: linear-gradient(to left, rgba(#000,0.75), rgba(#000,1) 75%);
                }
            }
        }
    }

    .banner-text {
        @include emphasized_text;
        font-size: 16px;
        line-height: 32px;
    }
}

@media screen and (max-width:500px) {
    .alternating_banner_section {
        min-height: 100vh;
        height: auto;
    }

    .banner-text {
        @include emphasized_text;
        font-size: 12px;
        line-height: 24px;
    }
}