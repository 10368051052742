@import "../scss/variables";

$swiper-pagination-height: 50px;

.swiper {
    width: 100%;
    height: calc(100vh - $header_height - $footer_height);
}

.swiper-slide {
    .full_width {
        height: 100%;

        &>div {
            height: 100%;
        }
    }

    .banner-content-wrapper {
        margin-top: -$swiper-pagination-height;
    }
}

.swiper-pagination {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    height: $swiper-pagination-height;
    display: flex;
    column-gap: 1px;
    justify-content: center;
    align-items: center;

    .swiper-custom-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 100%;
        flex: 1 1 0px;
        margin: 0 !important;
        background: #4c4c4c;
        opacity: 0.85;
        border-radius: 0;
        padding: 0 5px;

        span {
            @include label_text;
            color: #e8e8e8;
            text-align: center;
            line-height: 14px;
        }

        &.swiper-pagination-bullet-active {
            background: #000 !important;
            opacity: 1;

            span {
                color: $light-grey;
            }
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    color: $medium-grey !important;
    width: 44px !important;
    border: 1px solid $medium-grey;
    border-radius: 22px;
    @include standard-transition(all, 0.25s, 0s);

    &::after {
        transform: scale(0.5) translateX(3px);
    }

    &:hover {
        background: $medium-grey;

        &::after {
            color: $dark-grey;
        }
    }
}

.swiper-button-prev {

    &::after {
        transform: scale(0.5) translateX(-3px);
    }
}

@media screen and (max-width:760px) {

    .swiper {
        .swiper-button-next,
        .swiper-button-prev {
           width: 30px !important;
           height: 30px;
           right: 5px;
    
           &::after {
            font-size: 22px;
            line-height: 22px;
            transform: scale(0.75) translateX(1px);
           }

           &:hover {
            background: $light-grey;
           }
        }
    
        .swiper-button-prev {
            left: 5px;

            &::after {
                transform: scale(0.75) translateX(-1px);
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .swiper {

        .alternating_banner_section .banner-content {

            h2 {
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 0;
            }
    
            p {
                font-size: 12px;
                line-height: 18px;
                max-width: 85%;
            }
        }  
        
        .swiper-button-next,
        .swiper-button-prev {
           bottom: 10px;
           top: auto;
           left: 50%;
           transform: translateX(-50%);
           margin-left: -25px;
        }

        .swiper-button-next {
            margin-left: 25px;
        }


        .swiper-pagination {
            flex-direction: column;
            column-gap: 0;
            row-gap: 1px;
            right: 0;
            top: 0;
            left: auto !important;
            height: calc(100vh - $header_height - $footer_height);
            width: $swiper-pagination-height !important;
            overflow: hidden;

            span {
                transform: rotate(-90deg);
                font-size: 10px;
                line-height: 12px;
            }
        }
    }

    .banner-content-wrapper {
        margin-top: 0;
    }
}

@media screen and (max-width: 500px) {
    .swiper .alternating_banner_section {
        height: 100%;
        min-height: 100%;
    }
}