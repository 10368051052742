// MARGINS, PADDINGS, SISZES
$section_spacer: 50px;
$header_height: 60px;
$footer_height: 40px;

// COLORS
$dark-grey: #303030;
$medium-grey: #BEBEBE;
$light-grey: #F5F5F5;

// GRADIENTS & SHADOWS
$grey-transparent-gradient: linear-gradient(325deg, #FFFFFF 35%, #E5E5E5 100%);
$black-transparent-black-gradient: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.90));

@mixin drop-shadow-dark {
	-webkit-filter: drop-shadow(0px 5px 10px rgba(#000, .5));
    filter: drop-shadow(0px 5px 10px rgba(#000, .5));
}

@mixin drop-shadow-light {
	-webkit-filter: drop-shadow(0px 5px 10px rgba($medium-grey, 1.0));
    filter: drop-shadow(0px 5px 10px rgba($medium-grey, 1.0));
}

// TRANSITIONS
@mixin standard-transition($property, $duration: 0.1s, $delay: 0s) {
	-webkit-transition-property: $property;
    -webkit-transition-duration: $duration;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: ease-in-out;
	transition-delay: $delay;
}

// TEXT PRESETS
@mixin primary_headline {
	color: $dark-grey;
	font-family: 'AdventPro_Light';
	font-size: 60px;
	line-height: 80px;
}

@mixin secondary_headline {
	color: $dark-grey;
	font-family: 'AdventPro_Medium';
	font-weight: 500;
	font-size: 32px;
	line-height: 32px;
}

@mixin tile_headline {
	color: $light-grey;
	font-family: 'AdventPro_Light';
	font-weight: 300;
	font-size: 27px;
	line-height: 36px;
	text-align: center;
}

@mixin simple_text($color: $dark-grey) {
	color: $color;
	font-family: 'Raleway_Light';
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
}

@mixin emphasized_text($color: $light-grey) {
	color: $color;
	font-family: 'Raleway_MediumItalic';
	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
}

@mixin larg_ul_text($color: $dark-grey) {
	color: $color;
	font-family: 'Raleway_Medium';
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}

@mixin label_text($color: $dark-grey) {
	color: $color;
	font-family: 'Raleway_Bold';
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
}

@mixin tag_text($color: $light-grey) {
	@include label_text($color);
	text-transform: capitalize;
}

@mixin nav_item_text($color: $medium-grey) {
	color: $color;
	font-family: 'AdventPro_Bold';
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-transform: uppercase;
}

// COMPONETS
.button {
    display: block;
    width: 100%;
    background: #F5F5F5;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    font-family: "Raleway_Bold";
    text-align: center;
	height: 40px;
    color: #303030;
    padding-top: 12px;
    opacity: 0.85;
}